export default class CookieManager {
    /**
     * Store a cookie
     * source: http://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript
     *
     * @param string    name
     * @param string    value
     * @param int       days
     */
    createCookie(name,value,days) {
        if (days) {
            var date = new Date();
            date.setTime(date.getTime()+(days*24*60*60*1000));
            var expires = "; expires="+date.toGMTString();
        }
        else var expires = "";
        document.cookie = name+"="+value+expires+"; path=/";
    }

    /**
     * Read a cookie from the browser
     * source: http://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript
     *
     * @param string    name
     * @return string
     */
    readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    /**
     * Delete a cookie from the browser
     * source: http://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript
     *
     * @param string    name
     */
    eraseCookie(name) {
        this.createCookie(name,"",-1);
    }
}
