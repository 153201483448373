export default class Form {
    constructor(responseRenderer, cookieManager) {
        this.renderer = responseRenderer;
        this.cookieManager = cookieManager;
    }

    setCallback(callback) {
        this.callback = callback;
    }

    setCallbackError(callbackError) {
        this.callbackError = callbackError;
    }

    runCallback(callback, form, json) {
        if (typeof callback !== 'undefined') {
            if (typeof callback == 'string') {
                callback = window[callback];
            }

            callback(form, json);
        }
    }

    resetBtns(btns) {
        btns.each(function () {
            if ($(this).is('input')) {
                $(this).val($(this).data('fa-old'));
            } else {
                $(this).html($(this).data('fa-old'));
            }
            $(this).prop('disabled', false);
        });
    }

    submit(form) {
        // any messages should be hidden then reloaded in
        // in order to indicate if the form submitted, even
        // if it results in the same message
        $('.alert').hide();

        // set any buttons in the form to show loading and disable
        // to prevent multiple clicks
        var btns = $(form).find('.btn:not(.formAlertHideLoading)');
        btns.each(function () {
            var val;
            if ($(this).is('input')) {
                val = $(this).val();
                $(this).val('loading');
            } else {
                val = $(this).html();
                $(this).html('loading');
            }
            $(this).data('fa-old', val);
            $(this).prop('disabled', true);
        });

        var $this = this;
        var values = new FormData(form);
        var clicked = $('.formAlertClicked');
        values.append(clicked.attr('name'), clicked.val());

        $.ajax({
                url: $(form).attr('action'),
                type: 'POST',
                data: values,
                cache: false,
                contentType: false,
                processData: false
            })
            .done(function (data) {
                // re-enable form buttons
                $this.resetBtns(btns);

                if (typeof data === 'object') {
                    var json = data;
                } else {
                    try {
                        var json = $.parseJSON(data);
                    }
                    catch(err) {
                        // invalid JSON means some server-side error
                        // we'd still like to be able to handle that
                        $this.renderer.fail(data);
                        return;
                    }
                }

                if (
                    typeof json['redirect'] !== "undefined"
                    && json['redirect'].length > 0
                ) {
                    // handle redirect
                    // save messages to display on redirect landing
                    $this.cookieManager.createCookie('redirectMessages', data, 1);
                    window.location = json['redirect'];
                    return;
                }

                $this.renderer.done(json);

                if (typeof json['errors'] !== "undefined") {
                    $this.runCallback($this.callbackError, form, json);
                    return false;
                }

                $this.runCallback($this.callback, form, json);
            }).fail(function (data) {
                // server error
                // try to display what information we do get
                $this.resetBtns(btns);
                $this.renderer.fail(data);
            });

        return false;
    }
}
