import Message from './Message';

/**
 * @class Messages
 * maanges sets of messages for display
 */
export default class Messages {
    constructor(jsonMessages) {
        this.messages = {};

        for (var type in jsonMessages) {
            var arr = [];
            for (var i in jsonMessages[type]) {
                arr.push(new Message(jsonMessages[type][i]));
            }
            this.messages[type] = arr;
        }
    }

    show() {
        $('.field-error').removeClass('field-error');
        $('.fieldMessage').hide();
        for (var type in this.messages) {
            for (var index in this.messages[type]) {
                if ($('#'+type).length > 0) {
                    // clear top level alert messages
                    $('#'+type).html('').hide();
                }

                var message = this.messages[type][index]['message'];
                var target = $(message.target).length > 0 ? message.target : '#'+type;
                // clear field level messages
                $(message.target + '.fieldMessage').html('').hide();
            }

            for (var i in this.messages[type]) {
                // show each message
                var message = this.messages[type][i];
                var target = $(message.target).length > 0 ? message.target : '#'+type;
                message.show(type, $(target).data('timeout'));
            }

            if ($('#'+type).length > 0) {
                // if any messages were shown, scroll to the container
                $('html, body').animate({
                    scrollTop: $("#"+type).offset().top - 10
                }, 200);
            }
        }
    }
}
