/**
 * @class Message
 * text to show to the user
 */
export default class Message {
    constructor(message) {
        this.message = message;
    }

    /**
     * display a message to the user
     *
     * @param string    type        also used to determine the element to show message in
     * @param int       fadeoutTime how long to wait until message disappears (null or 0 for never)
     * @return void
     */
    show(type, fadeOutTime) {
        // if no messages have been shown yet, the list doesn't exist
        // create the list before trying to append to it
        var target = $(this.message.target);

        if (!target.hasClass('alert')) {
            return this.showField(type, fadeOutTime, target);
        }

        var list = target.find('ul');
        if (!list.length) {
            target.html('<ul></ul>');
            list = target.find('ul');
        }

        list.append('<li>'+this.tickToHtml(this.message.friendly)+'</li>');

        // don't try to re-fadein after every message, only the first one
        if (!target.is(':visible')) {
            target.fadeIn();
        }

        // generally want to fade out success messages after a short period
        if (fadeOutTime) {
            setTimeout(function(type) {
                $('#'+type).fadeOut();
            }, fadeOutTime, type);
        }
    }

    /**
     * Show a message for a specific form field
     *
     * @param string type
     * @param int    fadeOutTime
     * @param string target
     * @return void
     */
    showField(type, fadeOutTime, target) {
        target.removeClass('field-'+type).addClass('field-'+type);
        target.find('div.fieldMessage').remove();
        target.append('<div class="fieldMessage fieldMessage-'+type+'">'+this.message.friendly+'</span>');
    }

    /**
     * Covert backticks to an HTML tag enclosure in a string
     *
     * @param string    string      string to replace backticks in
     * @param string    tag         HTML tag to use to replace
     * @return string
     */
    tickToHtml(string, tag) {
        if (typeof tag === 'undefined')
            tag = 'strong';

        return string.replace(/`(.*?)`/g, '<'+tag+'>$1</'+tag+'>');
    }
}
