import Form from './Form';
import AlertRenderer from './ResponseRenderers/AlertRenderer';
import CookieManager from './Http/CookieManager';

window.formAlertRenderer = new AlertRenderer;

(function ($) {
    // jQuery plugin to be able to call formAlert() on any form to apply
    var form = new Form(formAlertRenderer, new CookieManager());
    $.fn.formAlert = function () {
        $(this).submit(function () {
            form.setCallback($(this).data('callback'));
            form.setCallbackError($(this).data('callback-error'));
            return form.submit(this);
        });
    };
}(jQuery));

$(document).ready(function () {
    var cookieManager = new CookieManager();

    // apply formAlert to all forms with the 'ajax' class
    $('body').on('submit', 'form.ajax', function () {
        var form = new Form(formAlertRenderer, cookieManager);
        form.setCallback($(this).data('callback'));
        form.setCallbackError($(this).data('callback-error'));
        return form.submit(this);
    });

    // add a formAlertClicked class on submit click to determine which
    // input was used to submit the form
    $('body').on('click', 'form.ajax .btn[type=submit]', function () {
        $('.btn[type=submit]').removeClass('formAlertClicked');
        $(this).addClass('formAlertClicked');
    });

    var cookie = "redirectMessages";
    var data = cookieManager.readCookie(cookie);
    cookieManager.eraseCookie(cookie);
    if (data) {
        formAlertRenderer.done(decodeURIComponent(data));
    }
});
