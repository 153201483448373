import AbstractRenderer from './AbstractRenderer';
import Messages from '../Containers/Messages';

export default class AlertRenderer extends AbstractRenderer {
    done(response) {
        new Messages(this.formatMessages(response)).show();
    }

    fail(response) {
        // HTTP error, could be anything coming back

        var error;
        try {
            var errors = $.parseJSON(response.responseText);
            error = errors;
        }
        catch (err) {
            if (typeof response.responseText != 'undefined')
                error = response.responseText;
            else
                error = response;
        }

        this.done(error);
    }

    formatMessages(jsonMessages) {
        // try to figure out what to display as the error message

        if (typeof jsonMessages == 'string') {
            try {
                jsonMessages = $.parseJSON(jsonMessages);
            } catch (err) {
                jsonMessages = { 'errors': { 'errors': [{'friendly': jsonMessages, 'target': 'errors'}] } };
            }
        }

        var messagesObj;
        if (typeof jsonMessages['errors'] != 'undefined') {
            messagesObj = jsonMessages['errors'];
        } else {
            messagesObj = jsonMessages['messages'];
        }

        return messagesObj;
    }
}
